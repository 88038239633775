import firebase from 'firebase/app'
import 'firebase/analytics'

export const dynamicLinkAPIKey = 'AIzaSyB-aWJC8cQ9Y5ORP5_B8KuU884qSQ2o9hw'

// staging
// const firebaseConfig = {
//   apiKey: 'AIzaSyAUI3Mc9OMzxiOVa0O0R4nOtsbH0TzwNBo',
//   authDomain: 'moinmoin-staging.firebaseapp.com',
//   databaseURL: 'https://moinmoin-staging-default-rtdb.europe-west1.firebasedatabase.app',
//   projectId: 'moinmoin-staging',
//   storageBucket: 'moinmoin-staging.appspot.com',
//   messagingSenderId: '1068047561229',
//   appId: '1:1068047561229:web:24c43ffda9d6cf3749db44',
//   measurementId: 'G-KXWZ4BCFSB'
// }

// Live:
const firebaseConfig = {
  apiKey: 'AIzaSyB-aWJC8cQ9Y5ORP5_B8KuU884qSQ2o9hw',
  authDomain: 'moinmoin-93181.firebaseapp.com',
  databaseURL: 'https://moinmoin-93181-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'moinmoin-93181',
  storageBucket: 'moinmoin-93181.appspot.com',
  messagingSenderId: '593909882860',
  appId: '1:593909882860:web:7cbc3f0c62705a3b8a2ac9',
  measurementId: 'G-WLWBGG3R65'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export function enableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(true)
}

export function disableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(false)
}
